import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then((m) => m.LoginPage),
  },
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
  },
  {
    path: '',
    redirectTo: 'login',  // Set login as the default route
    pathMatch: 'full',
  },
  {
    path: 'register-user',
    loadComponent: () => import('./pages/register-user/register-user.page').then(m => m.RegisterUserPage)
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.page').then(m => m.ProfilePage)
  },
  {
    path: 'register-user',
    loadComponent: () => import('./pages/register-user/register-user.page').then(m => m.RegisterUserPage)
  },
  {
    path: 'create-post',
    loadComponent: () => import('./pages/create-post/create-post.page').then(m => m.CreatePostPage)
  }
];
